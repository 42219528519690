import React, {useCallback, useEffect, useRef, useState} from 'react';
import './App.scss';

import {Route, Routes} from "react-router-dom";
import {getLocal, setLocal} from "./utils/dataFetching";

import Menu from "./components/general/Menu";
import Landing from "./components/general/Landing";
import Evaluation from './components/general/Evaluation';
import PromptTest from './components/general/PromptTest';
import {useAuth} from "./context/AuthContext";
import RolePlay from "./components/featureset/RolePlay";
import KnowledgeAssistant from './components/featureset/KnowledgeAssistant';
import Tester from "./components/general/Tester";
import VoiceTest from './components/general/VoiceTest';
import SummaryModules from './components/general/SummaryModules';
import Summaries from './components/general/Summaries';
import ViewLog from './components/general/ViewLog';
import Splash from './components/general/Splash';

const queryParams = new URLSearchParams(window.location.search);
const ccode = queryParams.get('key') || getLocal('ccode');
const datestart = queryParams.get('datestart');
const dateend = queryParams.get('dateend');

const nonTenantUrls = [
  'new',
  'prompt-test',
  'view-log',
  'voice-test'
];

window.onpopstate = () => {
  window.location.reload();
};

window.onpageshow = (e) => {
  if (e.persisted) {
    window.location.reload();
  }
};

function App() {
  document.title = 'AI Coach';

  const { isAuthenticated } = useAuth();
  //const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { login } = useAuth();

  const [tenant, setTenant] = useState(getLocal('tenant'));

  const navigate = useCallback ((p) => {
    // This currently fixes the issues encountered by switching between modes
    //    the speech recognition service doesn't get unloaded (commented out bc it was causing other issues)
    window.location = p;
  },[]);

  const attemptLogin = useCallback(async (ccode) => {
    //console.log(submitted);
    if (!submitted) {
      setSubmitted(true);
      console.log("");
      console.log('attempting Login', ccode);

      try {
        let loginResult = await login(ccode);
        console.log(loginResult);

        if (loginResult === 'wait') {
          //window.location.reload();
          return;
        }
        if (loginResult.status === 200) {
          //handleLoginSuccess(ccode);
        } else if (loginResult.status === 'error') {
          console.log('Invalid key');
        } else {
          console.log(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        //setTimeout(() => setSubmitted(false),2000);
      }
    }
  }, [login, submitted]);

  useEffect(() => {
    const attempt = async () => {
      console.log('effect',ccode,isAuthenticated, submitted);

      if (ccode && !isAuthenticated && !submitted) {
        console.log(isAuthenticated, submitted);
        await attemptLogin(ccode);
      }
    }

    attempt();
  }, [ attemptLogin, submitted, isAuthenticated]);

  const audioRef = useRef(null);
  const [audioPlayed, setAudioPlayed] = useState(false);

  const getTenantSlug = useCallback (() => {
    const p = window.location.pathname.split('/');
    return p.length > 0 ? p[1] : '';
  }, []);

  const getStep = useCallback (() => {
    const p = window.location.pathname.split('/');
    return (p.length > 0 ? p[p.length - 1] : '');
  }, []);

  const [step, setStep] = useState(getStep());

  const handleLogout = useCallback((e) => {
    e?.preventDefault();
    // localStorage.clear();
    // sessionStorage.clear();
    setLocal('auth_token','');
    setLocal('user','');
    setLocal('session_id','');
    navigate('/');
    setStep('');
  },[navigate]);

  const handleAppOnClick = useCallback(() => {
    if (!audioPlayed) {
      setAudioPlayed(true);
      if (!audioRef.current.paused && !audioRef.current.currentTime) {
        audioRef.current.src = '/speech.mp3';
        audioRef.current.play();
      }
    }
  },[audioPlayed]);

  useEffect(() => {
    //console.log('use EFFECT');
    async function fetchToken() {
      try {
        const token = getLocal("auth_token");
        if (!token) {
          // const API_TOKEN = await getToken();
          // localStorage.setItem("auth_token", API_TOKEN);
        }
      } catch (error) {}
    }

    fetchToken();

    return () => {
      //console.log('removing auth token');
      //localStorage.removeItem("auth_token");
    };
  }, []);

  useEffect(() => {
    // console.log('use EFFECT1');
    const currentSeg = getStep();

    if (step && step !== currentSeg) {
      //console.log('use EFFECT2', step, currentSeg);
      //navigate('/' + step);
      return;
    }

    if (isAuthenticated) {
      if (tenant && tenant.slug) {
        if (currentSeg !== 'menu' && currentSeg !== 'session-summaries' && !window.location.pathname.includes('/session-summaries/') && !nonTenantUrls.includes(currentSeg) && !tenant?.assistants?.find(a => a.slug === currentSeg) && !tenant?.scenarios?.find(s => s.slug === currentSeg || s.slug + '-evaluation' === currentSeg || s.slug + '-landing' === currentSeg || window.location.pathname.includes('/' + s.slug + '-evaluation/'))) {
          //console.log('use EFFECT3A');
          
          setStep('menu');
          navigate('/' + tenant.slug + '/menu');
          return;
        }
      }
    } else {
      if (currentSeg !== '' && currentSeg !== 'new') {
        //console.log('use EFFECT4');

        setStep('');
        navigate('/');
        return;
      }
    }

  }, [tenant, getTenantSlug, getStep, isAuthenticated, navigate, step, handleLogout ]);

  useEffect(() => {
    const assetsUrl = process.env.REACT_APP_ASSETS_URL;
    const slug = tenant?.slug || 'default';

    document.documentElement.style.setProperty('--tenant-logo', `url('${assetsUrl}/` + (tenant?.logo ? `${slug}/${tenant.logo}` : 'default/logo.png') + '\')');
  
    document.documentElement.style.setProperty('--assistant-bg-iphone', `url('${assetsUrl}/` + (tenant?.assistantBgiPhone ? `${slug}/${tenant.assistantBgiPhone}` : 'default/assistant-bg-iphone.png') + '\')');
    document.documentElement.style.setProperty('--assistant-bg-landscape', `url('${assetsUrl}/` + (tenant?.assistantBgLandscape ? `${slug}/${tenant.assistantBgLandscape}` : 'default/assistant-bg-landscape.png') + '\')');
    document.documentElement.style.setProperty('--assistant-bg-portrait', `url('${assetsUrl}/` + (tenant?.assistantBgPortrait ? `${slug}/${tenant.assistantBgPortrait}` : 'default/assistant-bg-portrait.png') + '\')');

    document.documentElement.style.setProperty('--scenario-bg-landscape', `url('${assetsUrl}/` + (tenant?.scenarioBgLandscape ? `${slug}/${tenant.scenarioBgLandscape}` : 'default/scenario-bg-landscape.png') + '\')');
    document.documentElement.style.setProperty('--scenario-bg-portrait', `url('${assetsUrl}/` + (tenant?.scenarioBgPortrait ? `${slug}/${tenant.scenarioBgPortrait}` : 'default/scenario-bg-portrait.png') + '\')');
    document.documentElement.style.setProperty('--scenario-processing', `url('${assetsUrl}/` + (tenant?.scenarioProcessing ? `${slug}/${tenant.scenarioProcessing}` : 'default/scenario-processing.gif') + '\')');
    document.documentElement.style.setProperty('--scenario-recording', `url('${assetsUrl}/` + (tenant?.scenarioRecording ? `${slug}/${tenant.scenarioRecording}` : 'default/scenario-recording.gif') + '\')');
  
    document.documentElement.style.setProperty('--button-end', `url('${assetsUrl}/` + (tenant?.buttonEnd ? `${slug}/${tenant.buttonEnd}` : 'default/button-end.svg') + '\')');
    document.documentElement.style.setProperty('--button-end-active', `url('${assetsUrl}/` + (tenant?.buttonEndActive ? `${slug}/${tenant.buttonEndActive}` : 'default/button-end-active.svg') + '\')');
    document.documentElement.style.setProperty('--button-refresh', `url('${assetsUrl}/` + (tenant?.buttonRefresh ? `${slug}/${tenant.buttonRefresh}` : 'default/button-refresh.png') + '\')');
    document.documentElement.style.setProperty('--button-talk', `url('${assetsUrl}/` + (tenant?.buttonTalk ? `${slug}/${tenant.buttonTalk}` : 'default/button-talk.svg') + '\')');
    document.documentElement.style.setProperty('--button-talk-active', `url('${assetsUrl}/` + (tenant?.buttonTalkActive ? `${slug}/${tenant.buttonTalkActive}` : 'default/button-talk-active.svg') + '\')');
    document.documentElement.style.setProperty('--button-talk-wait', `url('${assetsUrl}/` + (tenant?.buttonTalkWait ? `${slug}/${tenant.buttonTalkWait}` : 'default/button-talk-wait.svg') + '\')');
  }, [tenant]);

  const getClassNames = () => {
    const step = getStep();
    let classNames = [];

    classNames.push('col-12');
    classNames.push('App');

    if (tenant?.assistants?.find(a => a.slug === step)) {
      classNames.push('knowledge-assistant');
    } else if (tenant?.scenarios?.find(s => s.slug === step)) {
      classNames.push('role-play');
    } else if (tenant?.scenarios?.find(s => s.slug + '-evaluation' === step || window.location.pathname.includes('/' + s.slug + '-evaluation/'))) {
      classNames.push('role-play-evaluation');
    } else if (tenant?.scenarios?.find(s => s.slug + '-landing' === step)) {
      classNames.push('role-play-landing');
    } else if (step) {
      classNames.push(step);
    } else {
      classNames.push('splash');
    }

    if (audioPlayed) {
      classNames.push('audio-enabled');
    }

    return classNames.join(' ');
  };

  return (
    <div className={getClassNames()} onClick={handleAppOnClick}>
      {/*<Header handleLogout={handleLogout} isAuthenticated={isAuthenticated} />*/}
      {isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/new" element={<Tester />} />
          <Route path="/view-log" element={<ViewLog />} />
          <Route path="/voice-test" element={<VoiceTest />} />

          <Route path={'/' + tenant.slug + '/menu'} element={<Menu />} />
          <Route path={'/' + tenant.slug + '/session-summaries'} element={<SummaryModules datestart={datestart} dateend={dateend} />} />
          <Route path={'/' + tenant.slug + '/session-summaries/*'} element={<Summaries datestart={datestart} dateend={dateend} />} />

          {tenant?.scenarios && tenant.scenarios.map(s => {
            return (
              <React.Fragment key={s._id}>
                <Route path={'/' + tenant.slug + '/' + s.slug + '-landing'} element={<Landing scenario={s} />} />
                <Route path={'/' + tenant.slug + '/' + s.slug} element={<RolePlay scenario={s} audioRef={audioRef} />} />
                <Route path={'/' + tenant.slug + '/' + s.slug + '-evaluation/*'} element={<Evaluation scenario={s} />} />
                <Route path={'/' + tenant.slug + '/prompt-test'} element={<PromptTest tenantId={tenant._id} />} />
              </React.Fragment>
            );
          })}

          {tenant?.assistants && tenant.assistants.map(a => {
            return (
              <Route key={a._id} path={'/' + tenant.slug + '/' + a.slug} element={<KnowledgeAssistant assistant={a} audioRef={audioRef} audioPlayed={audioPlayed} />} />
            );
          })}
        </Routes>
      ) : (
        <Routes>
          {/*<Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />*/}
          <Route path="/" element={<Splash />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/new" element={<Tester />} />
        </Routes>
      )}
      <audio ref={audioRef} autoPlay src='/speech.mp3' crossOrigin="anonymous" />
    </div>
  );
}

export default App;
