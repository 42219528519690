import { useCallback, useState } from "react";
import {useAuth} from "../../context/AuthContext";
import {emptyLocal, getLocal} from "../../utils/dataFetching";
import Modal from './Modal';

import '../stylesheets/Menu.scss';
import logo from '../../assets/default/images/logo-splash.svg';

const Menu = () => {
  const { isAuthenticated } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get('key');

  const [tenant, setTenant] = useState(getLocal('tenant'));

  const logout = useCallback(() => {
    emptyLocal();
    window.location = '/';
  }, []);

  return (
    <>
      <div className="header">
        <img src={logo} alt="Xperiant: Human Potential" onClick={logout} />
      </div>
      <div className="menu-container">
        <h2>{tenant?.title} Modules</h2>
        {tenant?.scenarios && tenant.scenarios.filter(s => s.showOnMenu).map(s => {
          return (
            <button key={s._id} type="button" onClick={() => window.location = '/' + tenant.slug + '/' + s.slug + '-landing'}>{s.title}</button>
          );
        })}
        {tenant?.assistants && tenant.assistants.filter(a => a.showOnMenu).map(a => {
          return (
            <button key={a._id} type="button" onClick={() => window.location = '/' + tenant.slug + '/' + a.slug}>{a.title}</button>
          );
        })}
      </div>
      <Modal isOpen={!isAuthenticated && !key}>
        <span>We're sorry, but we were unable to create a user session.<br /><br />Please close this tab and try again.</span>
      </Modal>
    </>
  );
};

export default Menu;
