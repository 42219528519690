import { useState } from 'react';
import Modal from './Modal';
import {useAuth} from "../../context/AuthContext";
import {getTenantByCode} from "../../utils/auth";
import {splashContactLink} from "../../utils/constants";
import { emptyLocal, getLocal } from '../../utils/dataFetching';

import '../stylesheets/Splash.scss';
import logo from '../../assets/default/images/logo-splash.svg';

const Splash = () => {
  const { isAuthenticated } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get('key');
  
  if (isAuthenticated) {
    if (key) {
      const tenant = getLocal('tenant');

      if (tenant?.slug) {
        window.location = '/' + tenant.slug + '/menu';
      }
    } else {
      emptyLocal();
      window.location.reload();
    }
  }

  const [error, setError] = useState(null);

  const processForm = async (event) => {
    event.preventDefault();
    
    const accessCode = event.target.elements['access-code'].value;
    const response = await getTenantByCode(accessCode);

    if (response) {
      window.location = '/' + response.slug + '/menu';
      return;
    }

    setError('Invalid access code');
  };
  
  return (
    <>
      <div className="splash-container">
        <img src={logo} alt="Xperiant: Human Potential" />
        <p>Please enter your access code or <a href={splashContactLink}>contact</a> us to request a tenant</p>
        <form id="splash-form" onSubmit={processForm}>
          <input type="text" id="access-code" />
          <button type="submit">Enter</button>
        </form>
      </div>
      <Modal isOpen={error}>
        <span>{error}</span>
        <div className="d-flex">
          <button onClick={() => setError(null)}>OK</button>
        </div>
      </Modal>
    </>
  );
};

export default Splash;
